import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';
import alexgo from '../../assets/images/alexgo/alexg-banner.webp';
import alexgoOne from '../../assets/images/alexgo/alexgo-1.webp';
import alexgoTwo from '../../assets/images/alexgo/alexgo-2.webp';
import alexgoThree from '../../assets/images/alexgo/alexgo-3.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Alexgo extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={alexgo} alt="banner" />
          </section>
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Next Gen Decentralized Applications on Bitcoin
                </div>
                <div className="subtitle section-margin">
                  Building the financial infrastructure on Bitcoin through the Stacks blockchain
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#4472C6'}}> ALEXGO</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Optimize the web application’s front end which was taking a lot of time to
                      load, speed up the math library used on the contract side, and implement new
                      features.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Improved code quality on the web end, increased precision of the math library
                      from 8 to 16 decimal places, and implemented a complex farming feature UI.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      Better web performance, a higher accuracy of results, and reduced runtime of
                      functions which lowered the transaction fees on blockchain for features using
                      the math library.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://cryptonews.com/exclusives/the-growing-defi-market-on-bitcoin-whats-yielding-already.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      The Growing Defi Market on Bitcoin: what’s yielding already?
                    </a>{' '}
                    - Crypto News
                    <br />
                    <a
                      className="press-desc"
                      href="https://medium.com/alexgobtc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ALEX DeFi
                    </a>{' '}
                    - Medium
                  </p>
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100" src={alexgoOne} alt="alexgo" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://alexgo.io/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        ALEX
                      </a>{' '}
                      was building DeFi primitives targeting developers looking to build an
                      ecosystem on Bitcoin, enabled by the Stacks blockchain. The name ALEX stood
                      for Automated Liquidity EXchange. They focused on trading, lending and
                      borrowing of crypto assets with Bitcoin as the settlement layer and Stacks as
                      the smart contract layer. At the core of this focus was the automated market
                      making ("AMM") protocol, which allowed users to exchange one crypto asset with
                      another trustlessly.
                    </p>
                    <br />
                    <p className="description">
                      ALEX engaged Tintash as their development partner to work on increasing the
                      precision of math library (Clarity), optimizing the frontend which was taking
                      a lot of time to load, as well as implementing new features.
                    </p>
                  </div>

                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Tintash assigned two squads to the project. The first focused on frontend
                      development and included engineers skilled in ReactJS, while the second team
                      included blockchain engineers experienced in Clarity language for smart
                      contracts development on the Stacks Blockchain.
                    </p>
                    <br />
                    <p className="description mt-0">
                      The frontend team was responsible for optimizing the performance of the web
                      application and implementing an interactive user interface (UI) for a farming
                      feature.
                    </p>
                    <br />
                    <p className="description">
                      For performance optimization,  we thoroughly examined the codebase and
                      identified operations that were taking the most time. To address this problem,
                      we explored multiple solutions for a set of functions and suggested running
                      all data retrieval calls in parallel to monitor their impact. Once these
                      suggestions were approved by the client, the team implemented them across the
                      entire application. This allowed for a significant reduction in latency in
                      calculations and helped improve the overall user experience.
                    </p>
                    <br />
                    <p className="description">
                      The team then moved on to implementing a complex UI for a farming feature,
                      along with its integration with contract calls, to prepare for the public
                      launch.
                    </p>
                    <br />
                    <p className="description">
                      While working on the frontend, we also identified areas for improvement in the
                      existing codebase. We incorporated a better coding structure, improved code
                      quality on the web by using the latest features supported in ReactJS, and made
                      the code more extendable for future use. These efforts helped ensure that the
                      new feature was seamlessly integrated into the app, while making the product
                      better and more maintainable.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100" src={alexgoTwo} alt="alexgo" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      On the blockchain front, as the squad explored new ideas in the DeFi space,
                      their efforts focused on establishing the proofs of concept. Since the Clarity
                      language had limited in-built support and functionality, the blockchain
                      engineers conducted R&D to establish these proofs of concept for the math
                      library – an integral component to build features on top of it – as well as
                      the finance features to be implemented in smart contracts. To ensure the
                      validity of results, we wrote unit test cases and made a stronger case for our
                      recommendations. 
                    </p>
                    <br />
                    <p className="description mt-0">
                      Once these features were successfully implemented, we worked on improving the
                      precision of the math library used on the contract side. Since all
                      finance-related features required calculations, this math library was a
                      foundation on top of which other features were built. This optimization
                      reduced the time required for calculations and ensured faster and
                      feet-efficient transactions on the blockchain.
                    </p>
                    <br />
                    <p className="description">
                      Constant client communication and feedback was an integral part of our
                      development process. Throughout the project lifecycle, our team interacted
                      with the client through biweekly syncups, commits, and pull requests. This
                      helped streamline the process, incorporate the client’s feedback in a timely
                      manner, and ensure the final product met their expectations. The squad also
                      included a QA engineer who ensured the deliverables were of the highest
                      quality.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100" src={alexgoThree} alt="alexgo" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">What was the final outcome?</div>
                    <p className="description">
                      We improved the precision of the math library used on the contract side from 8
                      to 16 decimal places, allowing for more accurate results and reducing the
                      runtime of functions. That optimization helped lower the transaction fees on
                      the blockchain for AMM operations.
                    </p>
                    <br />
                    <p className="description mt-0">
                      We incorporated a better coding structure and improved code quality for the
                      web by using the latest features supported in tech stacks. By executing data
                      retrieval calls in parallel, we were able to speed up the process and make the
                      application more responsive. Code reviews were an integral part of the
                      development process and were carried out on both the contract side as well as
                      the frontend.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
                {/* <div className="col-12 p-0 my-5">
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeA} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Renters could easily find the right equipment
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeB} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Suggested pricing built to help owners remain competitive
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeC} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Owners could link items to inventory to increase rentals
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Alexgo;
